// UserContext.js
import React, { createContext, useState } from 'react';

// Create User Context
export const UserContext = createContext();

// UserProvider to wrap your app and provide context
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store registered user

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
