import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import DatePicker styles
import { FaAngleDown, FaAngleUp, FaCircle } from 'react-icons/fa';
import { link } from '../../constants/sidebar';
import { NavLink, useParams } from 'react-router-dom';
import { IoMdMail } from 'react-icons/io';
import { BsFillPersonFill } from 'react-icons/bs';

// Mock data for users
const users = {
  'ramya-borra': {
    name: 'Ramya Borra',
    role: 'HR',
    location: 'UK',
    email: 'ramyaborra@zarvisgenix.com',
  },
  'luke-johnson': {
    name: 'Luke Daniel Johnson',
    role: 'Secretary & Compliance',
    location: 'UK',
    email: 'lukejohnson@zarvisgenix.com',
  },
  'marlon-dassanayake': {
    name: 'Marlon Dassanayake',
    role: 'Intern',
    location: 'UK',
    email: 'marlondassanayake@zarvisgenix.com',
  },
  'rishabh-sinha': {
    name: 'Rishabh Sinha',
    role: 'Director & Technology',
    location: 'Uk',
    email: 'rishabhsinha@zarvisgenix.com',
  }
};
const TimesheetDashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [expandedRow, setExpandedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggleExpandRow = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };
  const { userId } = useParams(); // Get userId from URL
  const user = users[userId]; // Find user by ID
  const links = link(userId); 


  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsLoading(true); // Simulate loading
    setTimeout(() => setIsLoading(false), 1000); // Mock loading time
  };

  return (
	<section className='ml-6'>
    {/* Profile Section */}
    {user ? (
            <div className="flex flex-col md:flex-row  mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
              />
              <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
                <h1 className="text-2xl font-bold">{user.name}</h1>
                <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                  <FaCircle className="h-2 mt-2" color="green" /> {user.role}
                </p>
                <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">{user.location}</button>
              </div>
              <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
                <IoMdMail color="green" /> {user.email}
              </h1>
            </div>
          ) : (
            <div>User not found</div>
          )}

          {/* Navigation Links */}
          <ul className='flex gap-16 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>

    <div className="p-6 bg-white rounded-lg shadow-md">
      {/* Timesheet Overview Section */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <span className="text-sm text-gray-600">BST/GMT London, Europe</span>
        </div>
        <div className="flex space-x-4 text-sm text-gray-600">
          <button className="focus:outline-none">Today</button>
          <button className="focus:outline-none">Export</button>
          <button className="focus:outline-none">Yesterday</button>
          <button className="focus:outline-none font-semibold text-green-600">Past 7 days</button>
          <button className="focus:outline-none">Past 30 days</button>
          <div className="flex items-center">
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              className="focus:outline-none bg-gray-200 rounded px-2 py-1"
              placeholderText="Select date range"
            />
          </div>
        </div>
      </div>

      {/* Timesheet Statistics */}
      <div className="grid grid-cols-6 gap-4 mb-6">
        {['Total time tracked', 'Ideal minutes', 'Paid leaves', 'Productive website', 'Manual time', 'Mobile time'].map(
          (stat, index) => (
            <div key={index} className="relative p-4 bg-gray-100 rounded-lg group">
              <p className="text-gray-500 text-sm">{stat}</p>
              <p className="text-green-600 text-xl font-semibold">
                {index === 0 ? '130 hrs 04 min' : index === 3 ? '8 hrs 39 min' : '0 min'}
              </p>
              <div className="absolute top-0 right-0 bg-white p-2 text-xs text-gray-700 border rounded hidden group-hover:block">
                Detailed stats for {stat}
              </div>
            </div>
          )
        )}
      </div>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-green-600"></div>
        </div>
      )}

      {/* Timelines Table */}
      {!isLoading && (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr className="w-full bg-gray-100">
                <th className="py-3 px-4 text-left">Date</th>
                <th className="py-3 px-4 text-left">Time worked</th>
                <th className="py-3 px-4 text-left">Start time</th>
                <th className="py-3 px-4 text-left">End time</th>
                <th colSpan={11} className="py-3 px-4 text-left">
        
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
              {[0, 1, 2, 3].map((row, index) => (
                <tr key={index} className="border-t">
                  <td className="py-3 px-4">Thu, 12 Sep</td>
                  <td className="py-3 px-4">24 min</td>
                  <td className="py-3 px-4">11:02</td>
                  <td className="py-3 px-4">Ongoing</td>
                  <td colSpan={11} className="py-3 px-4">
                    <div className={`w-${index % 2 === 0 ? '4/5' : 'full'} h-1 bg-green-600`}></div>
                  </td>
                  <td className="px-2 cursor-pointer" onClick={() => toggleExpandRow(index)}>
                    {expandedRow === index ? <FaAngleUp /> : <FaAngleDown />}
                  </td>
                </tr>
              ))}
              {expandedRow !== null && (
                <tr className="border-t bg-gray-50">
                  <td colSpan={15} className="py-3 px-4">
                    <div className="p-4">
                      Expanded details for row {expandedRow + 1}. You can show charts, detailed time breakdown, or notes here.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
	</section>
  );
};

export default TimesheetDashboard;
