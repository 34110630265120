import React, { useState } from 'react';
import { FaEdit, FaCheck } from 'react-icons/fa';
import Salary from './Salary';
import Payroll from './Payroll';
import Bank from './Bank';
import Notes from './Notes';
import { useLocation } from 'react-router-dom';
const Job = ({ details }) => {
  // Default values for job details (not fetched from details)
  const [jobDetails, setJobDetails] = useState({
    jobTitle: details?.role, // Hardcoded default
    name:details?.name,
    contractType: 'Full-Time', // Hardcoded default
    team: details?.team, // Hardcoded default
    reportTo: 'Rishabh Sinha', // Hardcoded default
    probationRequired: 'Yes', // Hardcoded default
    probationEndDate: 'Jan 2025', // Hardcoded default
    noticeRequiredDuringProbation: 'Not specified', // Hardcoded default
    noticePeriod: 'Not specified' // Hardcoded default
  });

  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(jobDetails);
  const location = useLocation();
  const isPayrollVisible = location.pathname === '/contract/ramya-borra';
  const toggleEditMode = () => {
    if (isEditing) {
      setJobDetails(editedDetails); // Save edited details
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  const renderField = (label, field) => {
    if (isEditing) {
      return (
        <input
          type="text"
          name={field}
          value={editedDetails[field]}
          onChange={handleInputChange}
          className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
        />
      );
    } else {
      return <span>{jobDetails[field]}</span>;
    }
  };

  return (
    <div className="container ml-20 mx-auto font-poppins">
      <div className="bg-blue-400 rounded-xl p-6 shadow-lg mx-auto mt-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Job Details</h1>
          <button
            className="edit-btn h-9 bg-green-200 px-4 py-2 rounded-full flex items-center"
            onClick={toggleEditMode}
          >
            {isEditing ? (
              <>
                <FaCheck className="mr-2" />
                Save
              </>
            ) : (
              <>
                <FaEdit className="mr-2" />
                Edit
              </>
            )}
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            ['Job title', 'jobTitle'], // Hardcoded in jobDetails
            ['Name', 'name'], // Will not be fetched from details, stays static
            ['Contract type', 'contractType'], // Hardcoded in jobDetails
            ['Team(s)', 'team'], // Hardcoded in jobDetails
            ['Report to', 'reportTo'], // Hardcoded in jobDetails
            ['Probation required', 'probationRequired'], // Hardcoded in jobDetails
            ['Probation end date', 'probationEndDate'], // Hardcoded in jobDetails
            ['Notice required during Probation period', 'noticeRequiredDuringProbation'], // Hardcoded
            ['Notice period', 'noticePeriod'] // Hardcoded in jobDetails
          ].map(([label, field]) => (
            <div key={field} className="flex items-center text-blue-600">
              <label className="font-semibold w-1/3 text-blue-600">{label}:</label>
              <div className="w-2/3">
                {renderField(label, field)} {/* Dynamically render the field */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* Other components */}
      <Salary />
      {isPayrollVisible && <Payroll />} {/* Show Payroll if URL matches */}
      <Bank />
      <Notes />
    </div>
  );
};

export default Job;
