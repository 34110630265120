import React, { useState } from 'react';

const AddEmploye = () => {
  const [employee, setEmployee] = useState({
    name: '',
    email: '',
    department: '',
    role: ''
  });

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(employee);
    // Add your submit logic here (e.g., API call)
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-200 flex items-center justify-center">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
          Add New Employee
        </h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Employee Name */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold" htmlFor="name">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={employee.name}
              onChange={handleChange}
              placeholder="Enter full name"
              className="border border-gray-300 rounded-md py-2 px-4 mt-1 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-500"
              required
            />
          </div>

          {/* Employee Email */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold" htmlFor="email">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={employee.email}
              onChange={handleChange}
              placeholder="Enter email address"
              className="border border-gray-300 rounded-md py-2 px-4 mt-1 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-500"
              required
            />
          </div>

          {/* Department */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold" htmlFor="department">
              Department
            </label>
            <input
              type="text"
              id="department"
              name="department"
              value={employee.department}
              onChange={handleChange}
              placeholder="Enter department"
              className="border border-gray-300 rounded-md py-2 px-4 mt-1 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-500"
              required
            />
          </div>

          {/* Role */}
          <div className="flex flex-col">
            <label className="text-gray-700 font-semibold" htmlFor="role">
              Role/Position
            </label>
            <input
              type="text"
              id="role"
              name="role"
              value={employee.role}
              onChange={handleChange}
              placeholder="Enter role/position"
              className="border border-gray-300 rounded-md py-2 px-4 mt-1 focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-500"
              required
            />
          </div>

          {/* Submit Button */}
          <div className="flex justify-center  mt-6">
            <a href='/'
              type="submit"
              className="w-full pl-28 py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            >
              Add Employee
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEmploye;
