import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Calender from './Calender'
const Calendar = () => {
  return (
	<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
	 <Calender/>
	</div>
  </section>
  )
}

export default Calendar