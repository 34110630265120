import React, { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import sun from '../assets/sun.svg';
import frame from '../assets/frame.svg';

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const { email, password } = formData;

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <section className='flex flex-col space-x-10 md:flex-row items-center justify-center min-h-screen p-6 bg-gray-100'>
      <div className='flex flex-col items-start max-w-lg'>
        <img className='w-32 mx-auto mb-6' src={sun} alt="Sun" />
        <h1 className='text-blue-200 text-5xl font-semibold mb-4 text-center md:text-left'>Welcome Back!</h1>
        <p className='text-gray-700 mb-6 text-center md:text-left'>Please enter your details</p>
        <form className='w-full' onSubmit={handleSubmit}>
          <input
            className='block mb-4 w-full h-12 bg-blue-100 rounded-md px-4 text-lg'
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            placeholder="Email"
          />
          <div className="relative mb-4">
            <input
              className='block w-full h-12 bg-blue-100 rounded-md px-4 text-lg'
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={password}
              onChange={handleChange}
              placeholder="Password"
            />
            <button
              type="button"
              onClick={handlePasswordToggle}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              {showPassword ? <AiFillEyeInvisible size={24} /> : <AiFillEye size={24} />}
            </button>
          </div>
          <button
            className='w-full text-center bg-blue-200 h-12 rounded-xl text-white text-lg'
            type="submit"
          >
            Login
          </button>
        </form>
      </div>
      <div className='hidden md:block'>
        <img className='h-screen' src={frame} alt="Frame" />
      </div>
    </section>
  );
};

export default Login;
