import React from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { HiEye } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();

  // Navigate to the profile page with a specific userId
  const handleClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  return (
    <>
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-10 p-7 lg:p-14">
        {/* Left Column */}
        <div className="space-y-10">
          {/* First Section */}
          <div>
            <h1 className="text-xl font-semibold">HR & Business</h1>
            <div onClick={() => handleClick("ramya-borra")} className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-32 shadow-lg">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-5xl lg:text-7xl border h-14 lg:h-20 w-14 lg:w-20 p-1 rounded-full bg-white"
              />
              <div className="ml-5">
                <h1 className="text-lg font-semibold">Ramya Borra</h1>
                <div className="flex items-center mt-2 text-gray-700">
                  <HiEye color="red" className="mr-2" />
                  View full profile
                </div>
              </div>
            </div>
          </div>

          {/* Second Section */}
          <div>
            <h1 className="text-xl font-semibold">Secretary & Compliance</h1>
            <div onClick={() => handleClick("luke-johnson")} className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-32 shadow-lg">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-5xl lg:text-7xl border h-14 lg:h-20 w-14 lg:w-20 p-1 rounded-full bg-white"
              />
              <div className="ml-5">
                <h1 className="text-lg font-semibold">Luke Daniel Johnson</h1>
                <div className="flex items-center mt-2 text-gray-700">
                  <HiEye color="red" className="mr-2" />
                  View full profile
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-10">
          {/* Third Section */}
          <div>
            <h1 className="text-xl font-semibold">Director & Technology</h1>
            <div className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-32 shadow-lg">
              <div className="ml-5">
                <h1 className="pl-5 pt-7 text-lg font-poppins">NOT APPLICABLE</h1>
              </div>
            </div>
          </div>

          {/* Fourth Section */}
          <div>
            <h1 className="text-xl font-semibold">Interns</h1>
            <div onClick={() => handleClick("marlon-dassanayake")} className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-32 shadow-lg">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-5xl lg:text-7xl border h-14 lg:h-20 w-14 lg:w-20 p-1 rounded-full bg-white"
              />
              <div className="ml-5">
                <h1 className="text-lg font-semibold">Marlon Dassanayake</h1>
                <div className="flex items-center mt-2 text-gray-700">
                  <HiEye color="red" className="mr-2" />
                  View full profile
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* New Section */}
        <div>
          <h1 className="text-xl font-semibold">Director & Technology</h1>
          <div onClick={() => handleClick("rishabh-sinha")} className="cursor-pointer flex rounded-2xl bg-blue-100 mt-5 w-full lg:w-80 p-5 lg:p-7 h-auto lg:h-32 shadow-lg">
            <BsFillPersonFill
              color="rgba(207, 207, 207, 1)"
              className="text-5xl lg:text-7xl border h-14 lg:h-20 w-14 lg:w-20 p-1 rounded-full bg-white"
            />
            <div className="ml-5">
              <h1 className="text-lg font-semibold">Rishabh Sinha</h1>
              <div className="flex items-center mt-2 text-gray-700">
                <HiEye color="red" className="mr-2" />
                View full profile
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
