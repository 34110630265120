import React, { useState } from "react";
import image from "../../assets/image.png";

const LearningPart = () => {
  const [items, setItems] = useState([
    { id: 1, title: "Python Programming", description: "Python is a powerful, high-level programming language designed for simplicity and readability. Its clean syntax, which uses indentation to define code blocks, makes it easy to learn and write, especially for beginners. Python supports multiple programming paradigms, including procedural, object-oriented, and functional programming, making it highly versatile." },
    { id: 2, title: "Digital Marketing", description: "Python is increasingly being used in digital marketing due to its ability to automate repetitive tasks, analyze large datasets, and integrate with various marketing tools. With Python, marketers can efficiently scrape data from websites, manage SEO audits, and automate tasks such as email campaigns and social media posting." },
  ]);
  const [newItem, setNewItem] = useState({ title: "", description: "" });

  const handleAdd = () => {
    if (newItem.title && newItem.description) {
      setItems([...items, { id: items.length + 1, ...newItem }]);
      setNewItem({ title: "", description: "" });
    }
  };

  const handleDelete = (id) => {
    setItems(items.filter(item => item.id !== id));
  };

  return (
    <section className="p-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-10">
        {items.map(item => (
          <div key={item.id} className="flex flex-col items-center bg-blue-400 shadow-lg rounded-3xl p-4">
            <img className="h-48 w-full rounded-3xl object-cover mb-4" src={image} alt={item.title} />
            <div className="flex-grow text-center">
              <h1 className="text-2xl font-bold mb-2 text-gray-800">{item.title}</h1>
              <p className="text-gray-600 text-base leading-relaxed">{item.description}</p>
              <button onClick={() => handleDelete(item.id)} className="mt-4 bg-red-500 text-white p-2 rounded">Delete</button>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full bg-blue-400 shadow-lg rounded-3xl p-8">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">Add Learning Item</h1>
        <input 
          type="text" 
          placeholder="Title" 
          value={newItem.title} 
          onChange={(e) => setNewItem({ ...newItem, title: e.target.value })} 
          className="border p-2 mb-2 w-full"
        />
        <textarea 
          placeholder="Description" 
          value={newItem.description} 
          onChange={(e) => setNewItem({ ...newItem, description: e.target.value })} 
          className="border p-2 mb-2 w-full h-24"
        />
        <button onClick={handleAdd} className="bg-green-500 text-white p-2 rounded w-full">Add</button>
      </div>
    </section>
  );
};

export default LearningPart;
