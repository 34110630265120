import React from 'react';
import Sidebar from '../Sidebar';
import Navbar from '../Navbar';
import Contract from './Contract';

const Employe = () => {
  return (
    <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Contract />
      </div>
    </section>
  );
};

export default Employe;
