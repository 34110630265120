import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Docume from './Docume'

const DocMain = () => {
  return (
	<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
	  <Docume/>
	 
	</div>
  </section>
  )
}

export default DocMain