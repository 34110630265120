import React from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Reports from './Reports'

const ReportDashboard = () => {
  return (
<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
	 <Reports/>
	</div>
  </section>
  )
}

export default ReportDashboard