import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';

const EmergencyPart = ({user}) => {
  const [contactInfo, setContactInfo] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    homePhone: user?.phone,
    mobilePhone: user?.phone2,
    workPhone: user?.phone,
    emergency1: '+44 8897379797',
    emergency2: '+44 7425430918',
    relationshipStatus: 'Parents',
    address: 'Uk',
    country: 'Uk',
  });

  const [isEditing, setIsEditing] = useState(false);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="w-full p-4 bg-blue-100 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl font-semibold">Emergencies</h2>
        <button onClick={toggleEdit} className="text-green-600">
          <FaEdit size={24} />
        </button>
      </div>

      {/* First two rows with three columns */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        <div>
          <p className="text-gray-500">First Name</p>
          {isEditing ? (
            <input
              name="firstName"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={user.firstName}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{user.firstName}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Last name</p>
          {isEditing ? (
            <input
              name="lastName"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={user.lastName}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{user.lastName}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Home phone</p>
          {isEditing ? (
            <input
              name="homePhone"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.homePhone}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.homePhone}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Mobile phone</p>
          {isEditing ? (
            <input
              name="mobilePhone"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.mobilePhone}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.mobilePhone}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Work phone</p>
          {isEditing ? (
            <input
              name="workPhone"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.workPhone}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.workPhone}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Emergency no. 1</p>
          {isEditing ? (
            <input
              name="emergency1"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.emergency1}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.emergency1}</p>
          )}
        </div>
      </div>

      {/* Next two rows with two columns */}
      <div className="grid grid-cols-1 sm:grid-cols-2 w-custum gap-6 mt-4">
        <div>
          <p className="text-gray-500">Emergency no. 2</p>
          {isEditing ? (
            <input
              name="emergency2"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.emergency2}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.emergency2}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Relationship status</p>
          {isEditing ? (
            <input
              name="relationshipStatus"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.relationshipStatus}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.relationshipStatus}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Address</p>
          {isEditing ? (
            <input
              name="address"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.address}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.address}</p>
          )}
        </div>

        <div>
          <p className="text-gray-500">Country</p>
          {isEditing ? (
            <input
              name="country"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={contactInfo.country}
              onChange={handleInputChange}
            />
          ) : (
            <p className="font-medium text-lg">{contactInfo.country}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmergencyPart;
