import React from 'react'
import sinha from '../../assets/sinha.svg'
import intern from '../../assets/intern.svg'
import luke from '../../assets/luke.svg'
import ramya from '../../assets/ramya.svg'

const OrgDashboard = () => {
  return (
	< >
	<div className="">
		<h1 className='text-blue-200 font-bold text-center'>Organizational Structure Chart</h1>
		<div className="text-center flex justify-center ml-96 mt-6  bg-blue-100 h-12 w-64 rounded-lg  items-center"><h1>ZarvisGenix Limited</h1></div>
	</div>
	<section className="grid grid-cols-3   ">
		
		<div className="relative ml-7 mt-20">
		<img src={sinha} alt="" className="absolute top-0 left-0 pl-7   z-10" />
		  <div className="absolute top-28 left-0 text-center rounded-3xl  font-poppins bg-blue-100 h-52 w-80 z-0">
			{/* Content of the div */}
			<h1 className='pt-32 text-center text-blue-700 font-semibold'>Rishabh Raj Sinha</h1>
			<button className='border-black h-6 w-12 text-center '>Director</button>
		  </div>
		
		</div>
  
		
		<div className="relative ml-7 mt-20">
		<img src={luke} alt="" className="absolute top-0 left-0 pl-7   z-10" />
		  <div className="absolute top-28 left-0 text-center rounded-3xl  font-poppins bg-blue-100 h-52 w-80 z-0">
			{/* Content of the div */}
			<h1 className='pt-32 text-center text-blue-700 font-semibold'>Luke Daniel Johnson</h1>
			<button className='border-black h-6 w-12 text-center '>Company Secretary</button>
		  </div>
		 
		</div>
  
		<div className="relative ml-7 mt-20">
		<img src={ramya} alt="" className="absolute top-0 left-0 pl-7   z-10" />
		  <div className="absolute top-28 left-0 text-center rounded-3xl  font-poppins bg-blue-100 h-52 w-80 z-0">
			{/* Content of the div */}
			<h1 className='pt-32 text-center text-blue-700 font-semibold'>Business Support Manager</h1>
			<button className='border-black h-6 w-12 text-center '>Vacant</button>
		  </div>
		 
		</div>
	  
		<div className="relative  ml-7 mt-96">
		<img src={intern} alt="" className="absolute top-0 left-0 pl-7   z-10" />
		  <div className="absolute top-28 left-0 text-center rounded-3xl  font-poppins bg-blue-100 h-52 w-80 z-0">
			{/* Content of the div */}
			<h1 className='pt-32 text-center text-blue-700 font-semibold'>Marlon Dassanayake</h1>
			<button className='border-black h-6 w-12 text-center '>Intern</button>
		  </div>
		 
		</div>
	  </section>
	</>
    
  )
}

export default OrgDashboard
