import React from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import Data from './Data'

const DataDashboard = () => {
  return (
	<section>
    	 <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Data/>
      </div>
    </section>
  </section>
  )
}

export default DataDashboard