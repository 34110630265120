import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import PersonalDashboard from './PersonalDashboard'

const Personal = () => {
  return (
	<>
  <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <PersonalDashboard />
      </div>
    </section>
	
	</>
  )
}

export default Personal