import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import LearningPart from './LearningPart'

const Learning = () => {
  return (
<>
<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
       <LearningPart/>
      </div>
    </section>

</>
  )
}

export default Learning