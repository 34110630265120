import React, { useState } from "react";
import {  FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";

const Notes = () => {
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [salary, setSalary] = useState({
    employementNotes: "",
  });
  const [editedDetails, setEditedDetails] = useState(salary);

  // Toggle between expanded/collapsed view
  const toggle = () => {
    setExpanded(!expanded);
  };

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    if (isEditing) {
      // Save changes
      setSalary(editedDetails);
    }
    setIsEditing(!isEditing);
  };

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  return (
    <div className="container bg-blue-400 mt-7 p-6  rounded-lg shadow-lg ml-7 font-poppins">
      {/* Header section with toggle functionality */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <h1 className="text-xl font-bold"> Notes</h1>
        {expanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>

      {/* Description */}
      <p className="text-blue-600">Employment notes and other details</p>

      {/* Conditional rendering for expanded content */}
      {expanded && (
        <div className="mt-4">
          {isEditing ? (
            <>
              <div className="flex flex-col space-y-2">
                <label className="text-blue-600">Employement Notes</label>
                <input
                  type="text"
                  name="employementNotes"
                  value={editedDetails.employementNotes}
                  onChange={handleInputChange}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                />
              </div>
              <button
                onClick={toggleEdit}
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </>
          ) : (
            <div className="space-y-7 font-poppins">
              <div className="flex justify-between items-center">
                <p className="text-blue-600">Employment Notes:</p>
                <p className="bg-gray-200 py-1 px-3 rounded-md">
                  {salary.employementNotes || "No notes available"}
                </p>
              </div>

              <button
                className="edit-btn bg-green-200 px-4 py-2 rounded-full flex items-center"
                onClick={toggleEdit}
              >
                <FaEdit className="mr-2" />
                Edit
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notes;
