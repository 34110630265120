import React, { useState } from 'react';
import { IoMdMail } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';

const Timeline = () => {
  const [employees, setEmployees] = useState([
    { name: 'Luke', color: '#0f72e5', startTime: 2, endTime: 6 },
    
  
    { name: 'Rishabh', color: '#9b9b9b', startTime: 8, endTime: 14 },
  ]);

  const [editIndex, setEditIndex] = useState(null); // Keeps track of the employee being edited
  const [editField, setEditField] = useState(null); // Keeps track of which field (start or end) is being edited
  const [newTime, setNewTime] = useState({ hour: null, period: 'AM' }); // Holds the new time input (hour + AM/PM)
  const [showSaveMessage, setShowSaveMessage] = useState(false); // Tracks the "Time saved" message

  const hours = Array.from({ length: 12 }, (_, i) => i * 2); // Generates [0, 2, 4, ..., 22]

  const formatTime = (hour) => {
    const period = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:00 ${period}`;
  };

  const convertTo24Hour = (hour, period) => {
    if (period === 'PM' && hour < 12) return hour + 12;
    if (period === 'AM' && hour === 12) return 0; // Handle midnight case
    return hour;
  };

  const handleTimeChange = (e) => {
    setNewTime((prev) => ({
      ...prev,
      hour: Number(e.target.value),
    }));
  };

  const handlePeriodChange = (e) => {
    setNewTime((prev) => ({
      ...prev,
      period: e.target.value,
    }));
  };

  const handleTimeSubmit = (index) => {
    const updatedEmployees = [...employees];
    const timeIn24Hour = convertTo24Hour(newTime.hour, newTime.period); // Convert to 24-hour format

    if (editField === 'start') {
      updatedEmployees[index].startTime = timeIn24Hour;
    } else if (editField === 'end') {
      updatedEmployees[index].endTime = timeIn24Hour;
    }

    setEmployees(updatedEmployees);
    setEditIndex(null); 
    setEditField(null); 
    setShowSaveMessage(true);

    setTimeout(() => {
      setShowSaveMessage(false);
    }, 2000);
  };

  const handleEditTime = (index, field, currentHour) => {
    setEditIndex(index);
    setEditField(field);
    setNewTime({
      hour: currentHour % 12 || 12, 
      period: currentHour >= 12 ? 'PM' : 'AM', 
    });
  };

  return (
    <>


<div className="flex ml-7 bg-blue-100 h-52 p-5 rounded-3xl items-center mb-6">
        <BsFillPersonFill
          color="rgba(207, 207, 207, 1)"
          className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
        />
        <div className="ml-8 flex-1">
          <div className="flex space-x-14">
            <h1 className="text-2xl font-bold">Luke Daniel </h1>
            <h1 className="mt-4 text-lg font-medium flex items-center gap-3">
              <IoMdMail color="green" /> lukedaniel@zarvisgenix.com
            </h1>
          </div>
          <p className="text-gray-600 flex items-center gap-3">
            <FaCircle className="h-2 mt-2" color="green" /> HR
          </p>
          <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">UK</button>
        </div>
      </div>
         
    <div className='w-screen ml-6' style={{ padding: '20px', background: '#f5f6f7', borderRadius: '10px', width: '100%', }}>
      <h2 style={{ color: '#4a4a4a', paddingBottom: '10px' }}>Timelines</h2>

      {/* Display "Time saved" message */}
      {showSaveMessage && <div style={{ color: 'green', paddingBottom: '10px' }}>Time saved!</div>}

      {/* Employee Legend */}
      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', paddingBottom: '10px' }}>
        {employees.map((emp, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <div style={{ width: '20px', height: '10px', backgroundColor: emp.color, borderRadius: '3px' }}></div>
            <span style={{ fontSize: '14px', color: '#4a4a4a' }}>{emp.name}</span>
          </div>
        ))}
      </div>

      {/* Table Header */}
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr 6fr', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1px solid #e5e5e5' }}>
        <span>Time worked</span>
        <span>Start time</span>
        <span>End time</span>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', fontWeight: 'bold' }}>
          {hours.map((hour, index) => (
            <span key={index} style={{ textAlign: 'center', fontSize: '12px' }}>{formatTime(hour)}</span>
          ))}
        </div>
      </div>

      {/* Employee Rows */}
      {employees.map((emp, index) => (
        <div key={index} style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr 6fr', alignItems: 'center', padding: '10px 0', borderBottom: '1px solid #e5e5e5' }}>
          <span>{`${Math.floor((emp.endTime - emp.startTime) / 2)} hrs ${((emp.endTime - emp.startTime) % 2) * 30} min`}</span>

          {/* Start Time */}
          <span
            onClick={() => handleEditTime(index, 'start', emp.startTime)}
            style={{ cursor: 'pointer', color: editIndex === index && editField === 'start' ? '#f00' : '#000', transition: 'color 0.2s ease-in-out' }}
          >
            {editIndex === index && editField === 'start' ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="number"
                  value={newTime.hour !== null ? newTime.hour : emp.startTime % 12 || 12} // Convert 24-hour to 12-hour
                  min={1}
                  max={12}
                  onChange={handleTimeChange}
                  style={{
                    width: '60px',
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    fontSize: '14px',
                    transition: 'border-color 0.3s',
                    outline: 'none',
                  }}
                  onFocus={(e) => (e.target.style.borderColor = '#4a90e2')}
                  onBlur={(e) => (e.target.style.borderColor = '#ccc')}
                />
                <select
                  value={newTime.period}
                  onChange={handlePeriodChange}
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    fontSize: '14px',
                    outline: 'none',
                  }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
                <button
                  onClick={() => handleTimeSubmit(index)}
                  style={{
                    backgroundColor: '#4a90e2',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease-in-out',
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = '#357ab8')}
                  onMouseOut={(e) => (e.target.style.backgroundColor = '#4a90e2')}
                >
                  Save
                </button>
              </div>
            ) : (
              formatTime(emp.startTime)
            )}
          </span>

          {/* End Time */}
          <span
            onClick={() => handleEditTime(index, 'end', emp.endTime)}
            style={{ cursor: 'pointer', color: editIndex === index && editField === 'end' ? '#f00' : '#000', transition: 'color 0.2s ease-in-out' }}
          >
            {editIndex === index && editField === 'end' ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="number"
                  value={newTime.hour !== null ? newTime.hour : emp.endTime % 12 || 12} // Convert 24-hour to 12-hour
                  min={1}
                  max={12}
                  onChange={handleTimeChange}
                  style={{
                    width: '60px',
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    fontSize: '14px',
                    transition: 'border-color 0.3s',
                    outline: 'none',
                  }}
                  onFocus={(e) => (e.target.style.borderColor = '#4a90e2')}
                  onBlur={(e) => (e.target.style.borderColor = '#ccc')}
                />
                <select
                  value={newTime.period}
                  onChange={handlePeriodChange}
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    fontSize: '14px',
                    outline: 'none',
                  }}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
                <button
                  onClick={() => handleTimeSubmit(index)}
                  style={{
                    backgroundColor: '#4a90e2',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    fontSize: '14px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease-in-out',
                  }}
                  onMouseOver={(e) => (e.target.style.backgroundColor = '#357ab8')}
                  onMouseOut={(e) => (e.target.style.backgroundColor = '#4a90e2')}
                >
                  Save
                </button>
              </div>
            ) : (
              formatTime(emp.endTime)
            )}
          </span>

          {/* Timeline bars */}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', position: 'relative' }}>
            {hours.map((hour, idx) => (
              <span key={idx} style={{ borderRight: '1px solid #e5e5e5', height: '40px', position: 'relative' }}>
                {hour >= emp.startTime && hour < emp.endTime ? (
                  <div
                    style={{
                      position: 'absolute',
                      left: '0',
                      top: '50%',
                      width: '100%',
                      height: '4px',
                      backgroundColor: emp.color,
                      transform: 'translateY(-50%)',
                    }}
                  />
                ) : null}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default Timeline;
