import React, { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io'; // Import dropdown icon
import { links } from '../constants/sidebar';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentPath = window.location.pathname;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Button to toggle the sidebar (only on small screens) */}
      <button
        onClick={toggleSidebar}
        className="fixed top-4 left-4 z-50 text-white px-4 py-2 rounded-md md:hidden flex items-center"
      >
        <span>{isOpen ? 'Close Menu' : 'Open Menu'}</span>
        <IoMdArrowDropdown
          className={`ml-2 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`}
        />
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-white shadow-xl transition-transform transform md:translate-x-0 ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:relative md:translate-x-0 md:block`}
      >
        <ul className='mt-32 pl-7 text-lg space-y-5 font-poppins font-semibold'>
          {links.map((link, index) => (
            <li
              key={index}
              style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
              className={`p-2 rounded-lg ${currentPath === link.url ? 'bg-gray-100' : ''}`}
            >
              <img
                src={link.icon}
                alt={link.name}
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
              />
              <Link to={link.url}>{link.name}</Link> {/* Use Link instead of a */}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
