import React, { useState } from "react";
import { FaCheck, FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";

const Salary = () => {
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [salary, setSalary] = useState({
    salaryAmount: "5000",
    rate: "Hourly",
    currency: "GBP",
    paymentFrequency: "Monthly",
  });
  const [editedDetails, setEditedDetails] = useState(salary);

  // Toggle between expanded/collapsed view
  const toggle = () => {
    setExpanded(!expanded);
  };

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    if (isEditing) {
      // Save changes
      setSalary(editedDetails);
    }
    setIsEditing(!isEditing);
  };

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  return (
    <div className="container bg-blue-400 mt-7 p-6  rounded-lg shadow-lg ml-5 font-poppins">
      {/* Header section with toggle functionality */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <h1 className="text-xl font-bold">Salary Information</h1>
        {expanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>

      {/* Description */}
      <p className="text-blue-600">Salary amount, rate, and payment frequency</p>

      {/* Conditional rendering for expanded content */}
      {expanded && (
        <div className="mt-4">
          {isEditing ? (
            <>
              <div className="flex flex-col space-y-2">
                <label>Salary Amount</label>
                <input
                  type="text"
                  name="salaryAmount"
                  value={editedDetails.salaryAmount}
                  onChange={handleInputChange}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>Rate</label>
                <input
                  type="text"
                  name="rate"
                  value={editedDetails.rate}
                  onChange={handleInputChange}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>Currency</label>
                <input
                  type="text"
                  name="currency"
                  value={editedDetails.currency}
                  onChange={handleInputChange}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>Payment Frequency</label>
                <input
                  type="text"
                  name="paymentFrequency"
                  value={editedDetails.paymentFrequency}
                  onChange={handleInputChange}
                  className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                />
              </div>
              <button
                onClick={toggleEdit}
                className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg"
              >
                Save
              </button>
            </>
          ) : (
            <div className="space-y-7 font-poppins">
				<div className="flex font-poppins  ">
				<p className=" text-blue-600 font-poppins">Salary Amount: {salary.salaryAmount}</p>
				<button
            className="edit-btn h-9 ml-32  bg-green-200 px-4 py-2 rounded-full flex items-center"
            onClick={toggleEdit}
          >
            {isEditing ? (
              <>
                <FaCheck className="mr-2" />
                Save
              </>
            ) : (
              <>
                <FaEdit className="mr-2" />
                Edit
              </>
            )}
          </button>
				</div>
             
              <p className=" text-blue-600 font-poppins">Rate: {salary.rate}</p>
              <p className=" text-blue-600 font-poppins">Currency: {salary.currency}</p>
              <p className=" text-blue-600 font-poppins">Payment Frequency: {salary.paymentFrequency}</p>
              
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Salary;
