import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import ReportDashboard from './ReportDashboard'
const Report = () => {
  return (
	<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
	  <ReportDashboard/>
	</div>
  </section>
  )
}

export default Report