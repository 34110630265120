import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./components/Home";
import Login from './components/Login';
import Signup from './components/SignUp';

import Employe from "./components/employment/Employe";
import Personal from "./components/personal/Personal";
import Emergencies from "./components/emergency/Emergencies";
import Timesheet from "./components/timesheet/Timesheet";
import Document from "./components/documents/Document";

import AddEmploye from "./components/AddEmploye";
import Calendar from "./components/calender/Calendar";
import TimelineDashboard from "./components/timeline/TimelineDashboard";
import Learning from "./components/e-learning/Learning";
import Report from "./components/reports/Report";


import DataDashboard from "./dataprotection/DataDashboard";
import ReportDashboard from "./reports/ReportDashboard";
import Organisation from "./components/org/Organisation";

import OvertimeTimeline from "./components/overtime/OvertimeTimeline";

import Absense from "./components/ui/Absense";
import DocMain from "./components/doc/DocMain";



function App() {

  return (
    <Router>
      <div>
        {/* Render Home component here */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} /> {/* Pass setUser to Signup */}
          
          <Route path="/profile/:userId" element={<Absense />} />
          <Route path="/contract/:userId" element={<Employe />}/>
          
          <Route path="/personal/:userId" element={<Personal />} />
          <Route path="/emergency/:userId" element={<Emergencies />} />
          <Route path="/timesheet/:userId" element={<Timesheet />} />
          <Route path="/documents/:userId" element={<Document />} />
          <Route path="/documents" element={<DocMain />} />
          <Route path="/add" element={<AddEmploye />} /> {/* Pass user data to HomeS */}
          <Route path="/calender" element={<Calendar />} /> {/* Pass user data to HomeS */}
          <Route path="/clock-ins" element={<TimelineDashboard />} /> {/* Pass user data to HomeS */}
          <Route path="/e-learning" element={<Learning />} /> {/* Pass user data to HomeS */}
          <Route path="/payroll" element={<Report />} /> {/* Pass user data to HomeS */}
          <Route path="/expenses" element={<ReportDashboard />} /> {/* Pass user data to HomeS */}
          <Route path="/data" element={<DataDashboard />} /> {/* Pass user data to HomeS */}
          <Route path="/org" element={<Organisation />} /> {/* Pass user data to HomeS */}
          <Route path="/overtime/:userId" element={<OvertimeTimeline />} /> {/* Pass user data to HomeS */}
          {/* Pass user data to HomeS */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
