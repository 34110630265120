import React, { useState, useEffect } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaCircle } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

const Calendar = () => {
  const [events, setEvents] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [employee, setEmployee] = useState("");
  const [status, setStatus] = useState("Active");
  const [newEmployee, setNewEmployee] = useState("");
  const [feedback, setFeedback] = useState("");
  const [employees, setEmployees] = useState(["Luke", "Rishabh"]);
  const [currentMonth, setCurrentMonth] = useState(8); // September (0-based index)
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const storedEvents = JSON.parse(localStorage.getItem('calendarEvents')) || {};
    setEvents(storedEvents);
  }, []);

  useEffect(() => {
    localStorage.setItem('calendarEvents', JSON.stringify(events));
  }, [events]);

  const daysInMonth = new Date(2024, currentMonth + 1, 0).getDate();
  const firstDay = new Date(2024, currentMonth, 1).getDay();

  const handleDateClick = (day) => {
    const formattedDate = `2024-${(currentMonth + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    setSelectedDate(formattedDate);
    setIsModalOpen(true);
  };

  const handleAddEvent = () => {
    if (selectedDate && employee) {
      const newEvents = { ...events };
      if (!newEvents[selectedDate]) newEvents[selectedDate] = [];
      newEvents[selectedDate].push({ employee, status });
      setEvents(newEvents);
      setIsModalOpen(false);
      setEmployee("");
      setStatus("Active");
      setFeedback("Event added successfully!");
      setTimeout(() => setFeedback(""), 2000);
    }
  };

  const handleAddEmployee = () => {
    if (newEmployee && !employees.includes(newEmployee)) {
      setEmployees([...employees, newEmployee]);
      setNewEmployee("");
      setFeedback(`Employee ${newEmployee} added!`);
      setTimeout(() => setFeedback(""), 2000);
    }
  };

  const handleDeleteEvent = (dateKey, index) => {
    const updatedEvents = { ...events };
    updatedEvents[dateKey].splice(index, 1);
    if (updatedEvents[dateKey].length === 0) delete updatedEvents[dateKey];
    setEvents(updatedEvents);
    setFeedback("Event deleted!");
    setTimeout(() => setFeedback(""), 2000);
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < firstDay; i++) days.push(<div key={`empty-${i}`} className="empty-day" />);
    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `2024-${(currentMonth + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      const dayEvents = events[dateKey] || [];
      days.push(
        <div
          key={day}
          className="calendar-day border p-4 cursor-pointer text-2xl hover:bg-gray-200 transition-all duration-300 ease-in-out transform hover:scale-105"
          onClick={() => handleDateClick(day)}
        >
          <span className="font-bold">{day}</span>
          {dayEvents.length > 0 && (
            <div className="mt-2 space-y-1">
              {dayEvents.map((event, idx) => (
                <div
                  key={idx}
                  className={`event-label rounded cursor-pointer transition duration-200 ease-out hover:bg-opacity-75 ${event.status === 'Active' ? 'text-blue-700' : 'text-red-200'}`}
                  onClick={() => handleDeleteEvent(dateKey, idx)}
                >
                  <span className="font-medium">{event.employee}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    return days;
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => (prev === 0 ? 11 : prev - 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => (prev === 11 ? 0 : prev + 1));
  };

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <>
      <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
        <BsFillPersonFill
          color="rgba(207, 207, 207, 1)"
          className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
        />
        <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
          <h1 className="text-2xl font-bold">Luke Daniel johnson</h1>
          <p className="text-gray-600 flex justify-center md:justify-start gap-3">
            <FaCircle className="h-2 mt-2" color="green" /> HR
          </p>
          <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">UK</button>
        </div>
        <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
          <IoMdMail color="green" /> lukedaniel@zarvisgenix.com
        </h1>
      </div>

      <div className="calendar-container w-full mx-auto mt-16 p-8 rounded">
        <div className="calendar-header flex justify-between items-center mb-8">
          <button onClick={handlePreviousMonth} className="text-xl lg:text-2xl font-bold p-2">←</button>
          <h2 className="text-3xl lg:text-4xl font-bold">
            {new Date(2024, currentMonth).toLocaleString('default', { month: 'long' })}, 2024
          </h2>
          <button onClick={handleNextMonth} className="text-xl lg:text-2xl font-bold p-2">→</button>
        </div>

        <div className="grid grid-cols-7 gap-2 lg:gap-4 text-center text-xl lg:text-2xl font-semibold mb-4">
          {daysOfWeek.map((day, idx) => (
            <div key={idx} className="p-2">{day}</div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-2 lg:gap-4">
          {renderDays()}
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-lg w-full shadow-lg">
              <h3 className="text-xl lg:text-xl mb-4">Add Event for {selectedDate}</h3>
              <label className="block mb-4 text-lg">Select Employee:</label>
              <select
                className="border p-2 lg:p-3 w-full rounded mb-4 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                value={employee}
                onChange={(e) => setEmployee(e.target.value)}
              >
                <option value="">Select Employee</option>
                {employees.map((emp, idx) => (
                  <option key={idx} value={emp}>{emp}</option>
                ))}
              </select>

              <label className="block mb-4 text-lg">Select Status:</label>
              <select
                className="border p-2 lg:p-3 w-full rounded mb-4 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>

              <div className="flex justify-between">
                <button
                  className="bg-blue-600 text-white p-2 lg:p-3 rounded text-lg lg:text-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
                  onClick={handleAddEvent}
                >
                  Add Event
                </button>
                <button
                  className="bg-red-600 text-white p-2 lg:p-3 rounded text-lg lg:text-xl hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition-all duration-300"
                  onClick={() => setIsModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="add-employee-form mt-8">
          <h3 className="text-xl lg:text-2xl mb-4">Add New Employee</h3>
          <input
            className="border p-2 lg:p-3 w-full rounded mb-4 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            value={newEmployee}
            onChange={(e) => setNewEmployee(e.target.value)}
            placeholder="Enter Employee Name"
          />
          <button
            className="bg-green-600 text-white p-2 lg:p-3 rounded text-lg lg:text-xl hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-300"
            onClick={handleAddEmployee}
          >
            Add Employee
          </button>
        </div>

        {feedback && <p className="mt-4 text-lg text-green-600">{feedback}</p>}
      </div>
    </>
  );
};

export default Calendar;
