// src/components/Home.js
import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Profile from "./profile/Profile";
const Home = () => {
  return (
    <>
        <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Profile />
      </div>
    </section>
    </>
  );
};

export default Home;
