import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import AbsenseDashboard from './AbsenseDashboard'

const Absense = () => {
  return (
	<>
  <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <AbsenseDashboard />
      </div>
    </section>

	</>
  )
}

export default Absense