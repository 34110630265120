import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import DocumentPart from './DocumentPart'
const Document = () => {
  return (
	<>
	 <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
       <DocumentPart/>
      </div>
    </section>
	</>
  )
}

export default Document