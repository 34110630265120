import React, { useState } from "react";
import { IoMdMail } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";

// Component to display the document table
const Docume = () => {
  // Initial document data
  const [documents, setDocuments] = useState([
    {
      name: "Company Handbook",
      type: "Folder",
      size: "45.65 KB",
      dateCreated: "10 Sep 2024",
      visibility: "Admins & Manager",
    },
    {
      name: "Passport",
      type: "Word",
      size: "40.45 KB",
      dateCreated: "24 Aug 2024",
      visibility: "Admins & Manager",
    },
    {
      name: "Health & Safety Policy",
      type: "Word",
      size: "65.25 KB",
      dateCreated: "14 Aug 2024",
      visibility: "Admins & Manager",
    },
    {
      name: "Driving License",
      type: "Folder",
      size: "45.25 KB",
      dateCreated: "8 Aug 2024",
      visibility: "Admins & Manager",
    },
  ]);

  // State for new document input
  const [newDocument, setNewDocument] = useState({
    name: "",
    type: "Folder",
    size: "",
    dateCreated: new Date().toLocaleDateString(),
    visibility: "Admins & Manager",
  });

  // Handle input changes for the form
  const handleInputChange = (e) => {
    setNewDocument({ ...newDocument, [e.target.name]: e.target.value });
  };

  // Add a new document to the list
  const addDocument = () => {
    setDocuments([...documents, newDocument]);
    setNewDocument({
      name: "",
      type: "Folder",
      size: "",
      dateCreated: new Date().toLocaleDateString(),
      visibility: "Admins & Manager",
    });
  };

  // Delete a document from the list
  const deleteDocument = (index) => {
    const updatedDocuments = documents.filter((_, docIndex) => docIndex !== index);
    setDocuments(updatedDocuments);
  };

  // Update an existing document
  const updateDocument = (index, updatedDocument) => {
    const updatedDocuments = documents.map((doc, docIndex) =>
      docIndex === index ? updatedDocument : doc
    );
    setDocuments(updatedDocuments);
  };

  return (
    <>
      {/* User Information Section */}
      <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
        <BsFillPersonFill
          color="rgba(207, 207, 207, 1)"
          className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
        />
        <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
          <h1 className="text-2xl font-bold">Luke Daniel Johnson</h1>
          <p className="text-gray-600 flex justify-center md:justify-start gap-3">
            <FaCircle className="h-2 mt-2" color="green" /> HR
          </p>
          <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">UK</button>
        </div>
        <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
          <IoMdMail color="green" /> lukedaniel@example.com
        </h1>
      </div>

      {/* Document Table Section */}
      <div className="p-6 ml-6 bg-white rounded-lg shadow-md mt-8">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse">
            <thead>
              <tr className="w-full bg-green-100 text-left">
                <th className="py-3 px-6">Name</th>
                <th className="py-3 px-6">Type</th>
                <th className="py-3 px-6">Size</th>
                <th className="py-3 px-6">Date Created</th>
                <th className="py-3 px-6">Visibility</th>
                <th className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
              {documents.map((doc, index) => (
                <tr key={index} className="border-t border-gray-200 hover:bg-gray-50">
                  <td className="py-3 px-6">{doc.name}</td>
                  <td className="py-3 px-6">{doc.type}</td>
                  <td className="py-3 px-6">{doc.size}</td>
                  <td className="py-3 px-6">{doc.dateCreated}</td>
                  <td className="py-3 px-6">{doc.visibility}</td>
                  <td className="py-3 px-6 flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                    <button
                      onClick={() => deleteDocument(index)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md w-full md:w-auto"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() =>
                        updateDocument(index, {
                          ...doc,
                          name: prompt("Update Name", doc.name) || doc.name,
                        })
                      }
                      className="bg-blue-500 text-white px-3 py-1 rounded-md w-full md:w-auto"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Add New Document Section */}
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">Add New Document</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              value={newDocument.name}
              onChange={handleInputChange}
              placeholder="Document Name"
              className="border p-2 mb-2 w-full"
            />
            <select
              name="type"
              value={newDocument.type}
              onChange={handleInputChange}
              className="border p-2 mb-2 w-full"
            >
              <option value="Folder">Folder</option>
              <option value="Word">Word</option>
            </select>
            <input
              type="text"
              name="size"
              value={newDocument.size}
              onChange={handleInputChange}
              placeholder="Size (e.g., 45.65 KB)"
              className="border p-2 mb-2 w-full"
            />
            <input
              type="text"
              name="visibility"
              value={newDocument.visibility}
              onChange={handleInputChange}
              placeholder="Visibility"
              className="border p-2 mb-2 w-full"
            />
          </div>
          <button
            onClick={addDocument}
            className="bg-green-500 text-white py-2 px-4 mt-4 rounded-md"
          >
            Add Document
          </button>
        </div>
      </div>
    </>
  );
};

export default Docume;
