import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";

const Payroll = () => {
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [payrollDetails, setPayrollDetails] = useState({
    insurance: "SX812898C",
    passportNo: "S7755181",
    passportExpiration: "31 October 2028",
    visaNo: "RUX428806",
    visaExpiration: "14 September 2026",
    dbsCheck: "",
  });
  const [editedDetails, setEditedDetails] = useState(payrollDetails);
  const [visaExpirationTime, setVisaExpirationTime] = useState("");

  // Calculate time remaining for visa expiration
  useEffect(() => {
    const calculateExpirationTime = () => {
      const currentDate = new Date();
      const visaExpirationDate = new Date(payrollDetails.visaExpiration);
      
      let diffTime = visaExpirationDate - currentDate;

      // If visa is already expired
      if (diffTime < 0) {
        setVisaExpirationTime("Visa has expired.");
        return;
      }

      let years = visaExpirationDate.getUTCFullYear() - currentDate.getUTCFullYear();
      let months = visaExpirationDate.getUTCMonth() - currentDate.getUTCMonth();
      let days = visaExpirationDate.getUTCDate() - currentDate.getUTCDate();

      // Adjust the days and months if needed
      if (days < 0) {
        months -= 1;
        days += new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0).getUTCDate();
      }

      if (months < 0) {
        years -= 1;
        months += 12;
      }

      // Construct the expiration time string
      const expirationTime = `${years} year, ${months} months, ${days} days`;

      setVisaExpirationTime(expirationTime);
    };

    calculateExpirationTime();
  }, [payrollDetails.visaExpiration]);

  // Toggle between expanded/collapsed view
  const toggle = () => {
    setExpanded(!expanded);
  };

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    if (isEditing) {
      // Save changes
      setPayrollDetails(editedDetails);
    }
    setIsEditing(!isEditing);
  };

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  return (
    <div className="container bg-gray-100 p-6 rounded-lg shadow-lg mx-auto mt-7 ml-5">
      {/* Header section with toggle functionality */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <h1 className="text-xl font-bold">Payroll Information</h1>
        {expanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      <p className="text-blue-600">Payroll number and pension details</p>
      {/* Conditional rendering for expanded content */}
      {expanded && (
        <div className="mt-4">
          {isEditing ? (
            <>
              {/* Editing Mode */}
              <div className="space-y-4 font-poppins text-blue-600">
                <div className="flex flex-col space-y-2">
                  <label>National Insurance Number</label>
                  <input
                    type="text"
                    name="insurance"
                    value={editedDetails.insurance}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Passport Number</label>
                  <input
                    type="text"
                    name="passportNo"
                    value={editedDetails.passportNo}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Passport Expiration Date</label>
                  <input
                    type="text"
                    name="passportExpiration"
                    value={editedDetails.passportExpiration}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Visa Number</label>
                  <input
                    type="text"
                    name="visaNo"
                    value={editedDetails.visaNo}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>Visa Expiration Date</label>
                  <input
                    type="text"
                    name="visaExpiration"
                    value={editedDetails.visaExpiration}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label>DBS Check Expiration Date</label>
                  <input
                    type="text"
                    name="dbsCheck"
                    value={editedDetails.dbsCheck}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
                  />
                </div>
                <button
                  onClick={toggleEdit}
                  className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg"
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Viewing Mode */}
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="font-semibold font-poppins text-blue-600">
                    National Insurance Number:
                  </span>
                  <span className="bg-gray-200 py-1 px-3 rounded-md">
                    {payrollDetails.insurance}
                  </span>
                </div>

                <div>
                  <div className="flex justify-between">
                    <span className="font-semibold font-poppins text-blue-600">
                      Passport Number:
                    </span>
                    <span className="bg-gray-200 py-1 px-3 rounded-md">
                      {payrollDetails.passportNo}
                    </span>
                  </div>
                  <div className="flex justify-between mt-2">
                    <span className="font-semibold font-poppins text-blue-600">
                      Passport Expiration Date:
                    </span>
                    <span className="bg-gray-200 py-1 px-3 rounded-md">
                      {payrollDetails.passportExpiration}
                    </span>
                  </div>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold font-poppins text-blue-600">
                    Visa Number:
                  </span>
                  <span className="bg-gray-200 py-1 px-3 rounded-md w-2/3">
                    {payrollDetails.visaNo}
                  </span>
                </div>
                <div className="flex justify-between mt-2">
                  <span className="font-semibold font-poppins text-blue-600">
                    Visa Expiration Date:
                  </span>
                  <span className="bg-gray-200 py-1 px-3  rounded-md w-2/3">
                    {payrollDetails.visaExpiration}
                  </span>
                </div>
                <div className="mt-2 pl-64 text-green-600">
                  {visaExpirationTime}
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold font-poppins text-blue-600">
                    DBS Checks:
                  </span>
                  <span className="bg-gray-200 py-1 px-3 rounded-md">
                    {payrollDetails.dbsCheck}
                  </span>
                </div>
              </div>

              {/* Edit Button */}
              <button
                onClick={toggleEdit}
                className="mt-4 bg-green-200 px-4 py-2 rounded-full flex items-center"
              >
                <FaEdit className="mr-2" />
                Edit
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Payroll;
