import React from 'react'
import { BsFillPersonFill } from 'react-icons/bs'
import { FaCircle } from 'react-icons/fa'
import { IoMdMail } from 'react-icons/io'
import { link } from '../../constants/sidebar'
import { NavLink, useParams } from 'react-router-dom'
import EmergencyPart from './EmergencyPart'

// Mock data for users
const users = {
  'ramya-borra': {
    firstName: 'Ramya ',
    lastName: ' Borra',
    role: 'HR',
    location: 'UK',
    email: 'ramyaborra@zarvisgenix.com',
    phone: '+44 7425 448439', // Random UK number
    phone2: '+44 7467 448439', // Random UK number
  
  },
  'luke-johnson': {
    firstName: 'Luke ',
    lastName: 'Johnson ',
    role: 'Secretary & Compliance',
    location: 'UK',
    email: 'lukejohnson@zarvisgenix.com',
    phone: '+44 7425 444339', // Random UK number
    phone2: '+44 7467 476339', // Random UK number
  },
  'marlon-dassanayake': {
    firstName: 'Marlon ',
    lastName: 'Dassanayake ',
    role: 'Intern',
    location: 'UK',
    email: 'marlondassanayake@zarvisgenix.com',
    phone: '+44 7400 123456', // Random UK number
    phone2: '+44 7480 987654', // Random UK number
  },
  'rishabh-sinha': {
    firstName: 'Rishabh ',
    lastName: 'Sinha ',
    role: 'Director & Technology',
    location: 'Uk',
    email: 'rishabhsinha@zarvisgenix.com',
    phone: '+44 7480 765432', // Random UK number
    phone2: '+44 7425 876543', // Random UK number
  }
};
const EmergencyDashboard = () => {
  const { userId } = useParams(); // Get userId from URL
  const user = users[userId]; // Find user by ID
  const links = link(userId); 
  return (
	<>

	  <div className="mt-12  ml-6">
    {/* Profile Section */}
    {user ? (
            <div className="flex flex-col md:flex-row  mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
              />
              <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
                <h1 className="text-2xl font-bold">{user.firstName}{user.lastName}</h1>
                <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                  <FaCircle className="h-2 mt-2" color="green" /> {user.role}
                </p>
                <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">{user.location}</button>
              </div>
              <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
                <IoMdMail color="green" /> {user.email}
              </h1>
            </div>
          ) : (
            <div>User not found</div>
          )}

          {/* Navigation Links */}
          <ul className='flex gap-16 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>
		  <EmergencyPart user={user}/>
        
	  </div>









	</>
  )
}

export default EmergencyDashboard