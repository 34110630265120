import React, { useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { IoMdMail } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { NavLink, useParams } from 'react-router-dom';
import { link } from '../../constants/sidebar.js';

// Mock data for users
const users = {
  'ramya-borra': {
    name: 'Ramya Borra',
    role: 'HR',
    location: 'UK',
    email: 'ramyaborra@zarvisgenix.com',
  },
  'luke-johnson': {
    name: 'Luke Daniel Johnson',
    role: 'Secretary & Compliance',
    location: 'UK',
    email: 'lukejohnson@zarvisgenix.com',
  },
  'marlon-dassanayake': {
    name: 'Marlon Dassanayake',
    role: 'Intern',
    location: 'UK',
    email: 'marlondassanayake@zarvisgenix.com',
  },
  'rishabh-sinha': {
    name: 'Rishabh Sinha',
    role: 'Director & Technology',
    location: 'Uk',
    email: 'rishabhsinha@zarvisgenix.com',
  }
};

const AbsenseDashboard = () => {
  const { userId } = useParams(); // Get userId from URL
  const user = users[userId]; // Find user by ID
  const links = link(userId); 

  const [absences, setAbsences] = useState(0);
  const [sickness, setSickness] = useState(0);
  const [lateness, setLateness] = useState(0);

  const handleAddAbsence = () => setAbsences(absences + 1);
  const handleAddSickness = () => setSickness(sickness + 1);
  const handleAddLateness = () => setLateness(lateness + 1);

  return (
    <>
      <div className='mt-12'>
        <div className='ml-6'>
          {/* Profile Section */}
          {user ? (
            <div className="flex flex-col md:flex-row  mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
              />
              <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
                <h1 className="text-2xl font-bold">{user.name}</h1>
                <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                  <FaCircle className="h-2 mt-2" color="green" /> {user.role}
                </p>
                <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">{user.location}</button>
              </div>
              <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
                <IoMdMail color="green" /> {user.email}
              </h1>
            </div>
          ) : (
            <div>User not found</div>
          )}

          {/* Navigation Links */}
          <ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>

          {/* Dashboard Sections */}
          <div>
            <div className="bg-blue-400 h-full p-6 ml-6 rounded-b-xl">
              {/* Absences Section */}
              <div className="flex flex-col md:flex-row md:space-x-20 ml-32 md:ml-20">
                <div className="block ml-40 mb-4 md:mb-0">
                  <p className="font-medium text-center md:text-left">Absences</p>
                  <button className="bg-white h-12 rounded-xl w-full md:w-32 mt-3 text-sm">All Absences</button>
                </div>

                {/* Other Section */}
                <div className="block">
                  <p className="text-center md:text-left">Last year</p>
                  <button className="bg-white mt-3 h-12 rounded-xl w-full md:w-32 text-sm">01 Jan - 31 Dec</button>
                </div>
              </div>

              {/* Data and Button Controls */}
              <div className="flex flex-col md:flex-row md:space-x-12 ml-0 md:ml-8 mt-7">
                {/* Annual leaves */}
                <div className="bg-blue-300 h-52 p-5 rounded-xl w-full md:w-64 mb-4 md:mb-0">
                  <p>Annual leaves to take</p>
                  <p className="text-blue-500 text-center">{absences}/28</p>
                  <p className="text-center">days</p>
                  <button
                    onClick={handleAddAbsence}
                    className="bg-blue-200 h-11 w-full rounded-xl px-4 mt-2"
                  >
                    Add leave
                  </button>
                </div>

                {/* Sickness */}
                <div className="bg-blue-300 h-52 p-5 rounded-xl w-full md:w-64 mb-4 md:mb-0">
                  <p className="text-center">Sickness</p>
                  <p className="text-center text-blue-500">{sickness}</p>
                  <p className="text-center">Occurrences</p>
                  <button
                    onClick={handleAddSickness}
                    className="bg-blue-100 h-8 w-full rounded-xl px-4 mt-2"
                  >
                    Add
                  </button>
                </div>

                {/* Lateness */}
                <div className="bg-blue-300 h-52 p-5 rounded-xl w-full md:w-64 mb-4 md:mb-0">
                  <p className="text-center">Lateness</p>
                  <p className="text-center text-blue-500">{lateness}</p>
                  <p className="text-center">Occurrences</p>
                  <button
                    onClick={handleAddLateness}
                    className="bg-blue-100 h-8 w-full rounded-xl px-4 mt-2"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AbsenseDashboard;
