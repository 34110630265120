import React, { useState } from 'react';
import { NavLink , useParams } from 'react-router-dom';
import { link } from '../../constants/sidebar';
import { IoMdMail } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';

// Mock data for users
const users = {
  'ramya-borra': {
    name: 'Ramya Borra',
    role: 'HR',
    location: 'UK',
    email: 'ramyaborra@zarvisgenix.com',
  },
  'luke-johnson': {
    name: 'Luke Daniel Johnson',
    role: 'Secretary & Compliance',
    location: 'UK',
    email: 'lukejohnson@zarvisgenix.com',
  },
  'marlon-dassanayake': {
    name: 'Marlon Dassanayake',
    role: 'Intern',
    location: 'UK',
    email: 'marlondassanayake@zarvisgenix.com',
  },
  'rishabh-sinha': {
    name: 'Rishabh Sinha',
    role: 'Director & Technology',
    location: 'UK',
    email: 'rishabhsinha@zarvisgenix.com',
  }
};
const Overtime = () => {
  
  const initialData = [
    { date: 'Thu, 12 Sep', timeWorked: '24 min', overWorked: '06:02 PM', timeline: [{ start: 10, end: 11 }] },
    { date: 'Wed, 11 Sep', timeWorked: '6 hrs 02 min', overWorked: '05:43 PM', timeline: [{ start: 4, end: 12 }] },
    { date: 'Tue, 10 Sep', timeWorked: '6 hrs 05 min', overWorked: '06:00 PM', timeline: [{ start: 6, end: 10 }] },
    { date: 'Mon, 9 Sep', timeWorked: '6 hrs 10 min', overWorked: '07:20 PM', timeline: [{ start: 6, end: 12 }] },
  ];
  const { userId } = useParams(); // Get userId from URL
  const user = users[userId]; // Find user by ID
  const links = link(userId); 

  const [data, setData] = useState(initialData);
  const [editing, setEditing] = useState({ rowIndex: null, start: 0, end: 0 });
  const [editRow, setEditRow] = useState({ rowIndex: null, date: '', timeWorked: '', overWorked: '' });
  const [totalOvertime, setTotalOvertime] = useState(null);

  const hours = Array.from({ length: 12 }, (_, i) => i * 2);
  
  // Handle editing specific columns (Date, Time Worked, Over Worked)
  const handleRowEdit = (rowIndex) => {
    const { date, timeWorked, overWorked } = data[rowIndex];
    setEditRow({ rowIndex, date, timeWorked, overWorked });
  };

  // Save edited row details
  const handleRowSave = () => {
    const updatedData = data.map((row, index) => {
      if (index === editRow.rowIndex) {
        return { ...row, date: editRow.date, timeWorked: editRow.timeWorked, overWorked: editRow.overWorked };
      }
      return row;
    });

    setData(updatedData);
    setEditRow({ rowIndex: null, date: '', timeWorked: '', overWorked: '' });
  };

  // Handle cancel for row edit
  const handleRowCancel = () => {
    setEditRow({ rowIndex: null, date: '', timeWorked: '', overWorked: '' });
  };

  const handleTimelineClick = (rowIndex) => {
    const { start, end } = data[rowIndex].timeline[0];
    setEditing({ rowIndex, start, end });
  };

  const handleSave = () => {
    const updatedData = data.map((row, index) => {
      if (index === editing.rowIndex) {
        return {
          ...row,
          timeline: [{ start: editing.start, end: editing.end }],
        };
      }
      return row;
    });

    setData(updatedData);
    setEditing({ rowIndex: null, start: 0, end: 0 });
  };

  const calculateOvertime = () => {
    const total = data.reduce((sum, row) => {
      const workedHours = row.timeline[0].end - row.timeline[0].start;
      const overtime = workedHours > 8 ? workedHours - 8 : 0;
      return sum + overtime;
    }, 0);

    setTotalOvertime(total);
  };

  return (
    <>
     {/* Profile Section */}
     {user ? (
            <div className="flex flex-col md:flex-row ml-6 mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
              <BsFillPersonFill
                color="rgba(207, 207, 207, 1)"
                className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
              />
              <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
                <h1 className="text-2xl font-bold">{user.name}</h1>
                <p className="text-gray-600 flex justify-center md:justify-start gap-3">
                  <FaCircle className="h-2 mt-2" color="green" /> {user.role}
                </p>
                <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">{user.location}</button>
              </div>
              <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
                <IoMdMail color="green" /> {user.email}
              </h1>
            </div>
          ) : (
            <div>User not found</div>
          )}
  

  <ul className='flex gap-16 ml-6 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12'>
      {links.map((linkItem, index) => (
        <li key={index}>
          <NavLink
            to={linkItem.url}
            className={({ isActive }) =>
              isActive ? "text-blue-200 border-t-2 font-bold" : "text-gray-700"
            }
            end
          >
            {linkItem.name}
          </NavLink>
        </li>
      ))}
    </ul>

      <div className="font-poppins p-4 ml-3 ">
        <div className="overflow-x-auto rounded-lg shadow-md bg-white">
          <table className="min-w-full bg-white border-separate border-spacing-0">
            <thead>
              <tr>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left rounded-tl-lg">Date</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Time worked</th>
                <th className="py-4 px-6 bg-gray-100 text-gray-800 font-semibold text-left">Over worked</th>
                {hours.map((hour, index) => (
                  <th key={index} className="py-4 px-4 bg-gray-100 text-gray-600 font-semibold text-center">
                    {hour % 24 === 0 ? '12 AM' : hour % 24 < 12 ? `${hour} AM` : `${hour - 12} PM`}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="border-b">
                  {/* Date */}
                  <td className="py-4 px-6 text-gray-700 font-medium">
                    {editRow.rowIndex === rowIndex ? (
                      <input
                        type="text"
                        value={editRow.date}
                        onChange={(e) => setEditRow({ ...editRow, date: e.target.value })}
                        className="w-full px-2 py-1 border rounded-md"
                      />
                    ) : (
                      <span onClick={() => handleRowEdit(rowIndex)} className="cursor-pointer">
                        {row.date}
                      </span>
                    )}
                  </td>

                  {/* Time Worked */}
                  <td className="py-4 px-6 text-gray-700">
                    {editRow.rowIndex === rowIndex ? (
                      <input
                        type="text"
                        value={editRow.timeWorked}
                        onChange={(e) => setEditRow({ ...editRow, timeWorked: e.target.value })}
                        className="w-full px-2 py-1 border rounded-md"
                      />
                    ) : (
                      <span onClick={() => handleRowEdit(rowIndex)} className="cursor-pointer">
                        {row.timeWorked}
                      </span>
                    )}
                  </td>

                  {/* Over Worked */}
                  <td className="py-4 px-6 text-gray-700">
                    {editRow.rowIndex === rowIndex ? (
                      <input
                        type="text"
                        value={editRow.overWorked}
                        onChange={(e) => setEditRow({ ...editRow, overWorked: e.target.value })}
                        className="w-full px-2 py-1 border rounded-md"
                      />
                    ) : (
                      <span onClick={() => handleRowEdit(rowIndex)} className="cursor-pointer">
                        {row.overWorked}
                      </span>
                    )}
                  </td>

                  {/* Timeline */}
                  {hours.map((_, i) => (
                    <td
                      key={i}
                      className="py-4 px-4 text-center cursor-pointer"
                      onClick={() => handleTimelineClick(rowIndex)}
                    >
                      {row.timeline.some(t => t.start <= i && i <= t.end) ? (
                        <span className="block h-1 w-full bg-green-500 rounded-full" />
                      ) : (
                        <span className="block h-3 w-full bg-transparent" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Calculate Overtime Button */}
        <div className="mt-6 text-center">
          <button
            onClick={calculateOvertime}
            className="px-6 py-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700"
          >
            Calculate Overtime
          </button>
          {totalOvertime !== null && (
            <p className="mt-4 text-green-600 font-semibold">
              Total Overtime: {totalOvertime} hours
            </p>
          )}
        </div>

        {/* Modal for time editing */}
        {editing.rowIndex !== null && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-60 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
              <h3 className="text-xl mb-4 font-semibold">Edit Timeline for {data[editing.rowIndex].date}</h3>
              <div className="flex flex-col sm:flex-row gap-6">
                <div>
                  <label className="block text-gray-700 font-medium mb-2">Start Time:</label>
                  <select
                    value={editing.start}
                    onChange={(e) => setEditing({ ...editing, start: Number(e.target.value) })}
                    className="w-full px-3 py-2 border rounded-lg"
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour % 24 === 0 ? '12 AM' : hour % 24 < 12 ? `${hour} AM` : `${hour - 12} PM`}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-gray-700 font-medium mb-2">End Time:</label>
                  <select
                    value={editing.end}
                    onChange={(e) => setEditing({ ...editing, end: Number(e.target.value) })}
                    className="w-full px-3 py-2 border rounded-lg"
                  >
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>
                        {hour % 24 === 0 ? '12 AM' : hour % 24 < 12 ? `${hour} AM` : `${hour - 12} PM`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-4">
                <button onClick={handleSave} className="px-4 py-2 bg-blue-600 text-white rounded-md">
                  Save
                </button>
                <button
                  onClick={() => setEditing({ rowIndex: null, start: 0, end: 0 })}
                  className="px-4 py-2 bg-gray-400 text-white rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Save/Cancel for row edit */}
        {editRow.rowIndex !== null && (
          <div className="flex justify-end mt-4 gap-4">
            <button onClick={handleRowSave} className="px-4 py-2 bg-green-600 text-white rounded-md">
              Save
            </button>
            <button onClick={handleRowCancel} className="px-4 py-2 bg-red-600 text-white rounded-md">
              Cancel
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Overtime;
