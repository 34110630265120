import React, { useState } from "react";

const Reports = () => {
  const initialData = [
    {
      name: "Luke Daniel Johnson",
      department: "Secretary",
      dateSubmitted: "07/06/2024",
      dateApproved: "07/06/2024",
      approver: "Rishabh Sinha",
      net: "-",
      vat: "-",
      gross: 22.95,
      paid: 22.95,
    },
    {
      name: "Ramya Borra",
      department: "-",
      dateSubmitted: "11/06/2024",
      dateApproved: "11/06/2024",
      approver: "Rishabh Sinha",
      net: "-",
      vat: "-",
      gross: 20.25,
      paid: 22.25,
    },
    {
      name: "Rishabh Sinha",
      department: "-",
      dateSubmitted: "11/06/2024",
      dateApproved: "-",
      approver: "Rishabh Sinha",
      net: "-",
      vat: "-",
      gross: 22.85,
      paid: 15,
    },
    {
      name: "Marlon Dassanayake",
      department: "Intern",
      dateSubmitted: "24/05/2024",
      dateApproved: "24/05/2024",
      approver: "Rishabh Sinha",
      net: "-",
      vat: "-",
      gross: 24.35,
      paid: 50,
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");
  const [showExEmployees, setShowExEmployees] = useState(false);
  const [tableData, setTableData] = useState(initialData);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = () => {
    const sortedData = [...tableData].sort((a, b) => {
      return sortOrder === "asc" ? a.gross - b.gross : b.gross - a.gross;
    });
    setTableData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    const filteredData = initialData.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setTableData(filteredData);
  };

  const handleInputChange = (idx, key, value) => {
    const updatedData = tableData.map((item, index) => {
      if (index === idx) {
        return { ...item, [key]: value };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const handleDelete = (idx) => {
    const updatedData = tableData.filter((_, index) => index !== idx);
    setTableData(updatedData);
  };

  return (
    <div className="p-6">
      {/* Filter and Search Section */}
      <div className="flex flex-wrap gap-4 mb-6">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            className="h-5 w-5 text-green-600 rounded focus:ring focus:ring-green-500"
            onChange={() => setShowExEmployees(!showExEmployees)}
            checked={showExEmployees}
          />
          <span className="text-gray-700 font-medium">Show ex employees</span>
        </label>
      </div>

      {/* Search Bar */}
      <div className="flex items-center mb-4 space-x-2">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder='Search "Luke Daniel Johnson"'
          className="flex-1 p-3 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-green-500"
        />
        <button className="bg-green-500 text-white font-medium py-3 px-6 rounded-r-lg hover:bg-green-600 transition">
          Search
        </button>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
          <thead>
            <tr className="bg-green-100 text-left">
              <th className="p-4 text-gray-600 font-medium">Name</th>
              <th className="p-4 text-gray-600 font-medium">Department</th>
              <th className="p-4 text-gray-600 font-medium">Date Submitted</th>
              <th className="p-4 text-gray-600 font-medium">Date Approved</th>
              <th className="p-4 text-gray-600 font-medium">Approver</th>
              <th className="p-4 text-gray-600 font-medium">Net</th>
              <th className="p-4 text-gray-600 font-medium">VAT</th>
              <th
                className="p-4 text-gray-600 font-medium cursor-pointer"
                onClick={handleSort}
              >
                Gross {sortOrder === "asc" ? "↑" : "↓"}
              </th>
              <th className="p-4 text-gray-600 font-medium">Paid to Employee</th>
              <th className="p-4 text-gray-600 font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, idx) => (
              <tr key={idx} className="border-t hover:bg-gray-100 transition">
                <td className="p-4">{item.name}</td>
                <td className="p-4">{item.department}</td>
                <td className="p-4">{item.dateSubmitted}</td>
                <td className="p-4">{item.dateApproved}</td>
                <td className="p-4">{item.approver}</td>
                <td className="p-4">
                  <input
                    type="number"
                    value={item.net}
                    onChange={(e) => handleInputChange(idx, "net", e.target.value)}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="p-4">
                  <input
                    type="number"
                    value={item.vat}
                    onChange={(e) => handleInputChange(idx, "vat", e.target.value)}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="p-4">
                  <input
                    type="number"
                    value={item.gross}
                    onChange={(e) => handleInputChange(idx, "gross", e.target.value)}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="p-4">
                  <input
                    type="number"
                    value={item.paid}
                    onChange={(e) => handleInputChange(idx, "paid", e.target.value)}
                    className="border rounded p-1 w-full"
                  />
                </td>
                <td className="p-4">
                  <button
                    onClick={() => handleDelete(idx)}
                    className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reports;
