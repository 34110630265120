import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Timeline from './Timeline'

const TimelineDashboard = () => {
  return (
	<>
	<section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <Timeline/>
      </div>
    </section>
	</>
  )
}

export default TimelineDashboard