/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { FaEdit, FaCheck } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { link } from "../../constants/sidebar.js";
import { NavLink, useParams } from "react-router-dom";
import Job from "./Job.jsx";

// Mock user contract data
const userContracts = {
  "ramya-borra": {
    name: "Ramya Borra",
    email: "ramyaborra@zarvisgenix.com",
    role: "HR",
    location: "UK",
    team:"Hiring",
    entitlementUnit: "Days",
    annualLeaveBalance: 25,
    employmentType: "Full Time",
    startDate: "November 2024",
    workingPattern: "Monday-Friday (9-5)",
    contractedHours: 35,
    leaveYearStart: "01 Jan",
    minLeaveEntitlement: 25,
    address: "179 Lower Richmond Rd., London TW9 4LN, United Kingdom",
    publicHolidays: "England & Wales",
  },
  "luke-johnson": {
    name: "Luke Jhonson",
    email: "lukejhonson@zarvisgenix.com",
    role: "Manager",
    location: "UK",
    team:"Management",
    entitlementUnit: "Days",
    annualLeaveBalance: 25,
    employmentType: "Full Time",
    startDate: "May 2024",
    workingPattern: "Monday-Friday (9-5)",
    contractedHours: 35,
    leaveYearStart: "01 Jan",
    minLeaveEntitlement: 25,
    address: "179 Lower Richmond Rd., London TW9 4LN, United Kingdom",
    publicHolidays: "England & Wales",
  },
  "marlon-dassanayake": {
    name: "Marlon Dassanayake",
    email: "marlon@zarvisgenix.com",
    role: "Intern",
    location: "UK",
    team:"Development",
    entitlementUnit: "Days",
    annualLeaveBalance: 25,
    employmentType: "Full Time",
    startDate: "May 2024",
    workingPattern: "Monday-Friday (9-5)",
    contractedHours: 35,
    leaveYearStart: "01 Jan",
    minLeaveEntitlement: 25,
    address: "179 Lower Richmond Rd., London TW9 4LN, United Kingdom",
    publicHolidays: "England & Wales",
  },
  'rishabh-sinha': {
    name: "Rishabh Sinha",
    email: "rishabh@zarvisgenix.com",
    role: "Director  & Technology",
    location: "UK",
    team:"Technology",
    entitlementUnit: "Days",
    annualLeaveBalance: 25,
    employmentType: "Full Time",
    startDate: "November 2024",
    workingPattern: "Monday-Friday (9-5)",
    contractedHours: 35,
    leaveYearStart: "01 Jan",
    minLeaveEntitlement: 25,
    address: "179 Lower Richmond Rd., London TW9 4LN, United Kingdom",
    publicHolidays: "England & Wales",},
  // Add more users here...
};

const Contract = () => {
  const { userId } = useParams(); // Get userId from the route
  const user = userContracts[userId]; // Find contract details by userId
  const links = link(userId); 
  
  // Check if user exists, otherwise show 'User not found'
  if (!user) {
    return <div>User not found</div>;
  }

  // State for all contract and work details
  const [details, setDetails] = useState(user);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isEditing, setIsEditing] = useState(false);

  // Toggle the edit mode
  const toggleEditMode = () => {
    if (isEditing) {
      // Save changes when toggling off edit mode
      setDetails((prevDetails) => ({
        ...prevDetails,
      }));
    }
    setIsEditing((prev) => !prev);
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Render each field dynamically, either as text or input
  const renderField = (field, label) => {
    return isEditing ? (
      <input
        type="text"
        name={field}
        value={details[field]}
        onChange={handleInputChange}
        className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 w-full"
      />
    ) : (
      <span>{details[field]}</span>
    );
  };

  return (
    <div className="container ml-2 mx-auto p-4">
      {/* Profile Section */}
      <div className="flex flex-col md:flex-row  mt-10 bg-blue-100 h-auto p-5 rounded-3xl items-center">
        <BsFillPersonFill
          color="rgba(207, 207, 207, 1)"
          className="text-7xl border h-36 w-36 p-1 rounded-full bg-white"
        />
        <div className="ml-0 md:ml-8 mt-4 md:mt-0 text-center md:text-left">
          <h1 className="text-2xl font-bold">{details.name}</h1>
          <p className="text-gray-600 flex justify-center md:justify-start gap-3">
            <FaCircle className="h-2 mt-2" color="green" /> {details.role}
          </p>
          <button className="mt-1 bg-blue-300 py-2 px-4 rounded-3xl text-white">
            {details.location}
          </button>
        </div>
        <h1 className="mt-4 md:mt-0 md:ml-20 text-lg font-medium flex items-center gap-3 text-center md:text-left">
          <IoMdMail color="green" /> {details.email}
        </h1>
      </div>

      {/* Navigation Links */}
      <ul className="flex gap-16 text-lg p-4 bg-blue-300 mt-8 rounded-t-2xl h-12">
        {links.map((link, index) => (
          <li key={index}>
            <NavLink
              to={link.url}
              className={({ isActive }) =>
                isActive
                  ? "text-blue-200 border-t-2 font-bold"
                  : "text-gray-700"
              }
              end
            >
              {link.name}
            </NavLink>
          </li>
        ))}
      </ul>

      {/* Contract Details */}
      <div className="flex">
        <div className="w-5/6 font-poppins ml-2 bg-blue-400 rounded-xl p-6 shadow-lg mx-auto mt-4">
          <div className="header flex justify-between items-center mb-6">
            <div>
              <h1 className="text-xl font-bold font-poppins">
                Contract and Annual Leave Information
              </h1>
              <p className="text-sm text-gray-500">
                Contracted hours of work, employment start date, and leave
                entitled
              </p>
            </div>
            <button
              className="edit-btn bg-green-200 px-4 py-2 rounded-full flex items-center"
              onClick={toggleEditMode}
            >
              {isEditing ? (
                <>
                  <FaCheck className="mr-2" />
                  Save
                </>
              ) : (
                <>
                  <FaEdit className="mr-2" />
                  Edit
                </>
              )}
            </button>
          </div>

          {/* Contract Details */}
          <div className="space-y-4 font-thin">
            {[
              ["Entitlement unit in", "entitlementUnit"],
              ["Annual leave balance", "annualLeaveBalance"],
              ["Employment type", "employmentType"],
              ["Contract start date", "startDate"],
              ["Working time pattern", "workingPattern"],
              ["Contracted hours per week", "contractedHours"],
              ["Annual leave year start", "leaveYearStart"],
              ["Min. leave entitlement", "minLeaveEntitlement"],
            ].map(([label, field]) => (
              <div
                key={field}
                className="flex items-center text-blue-600 space-x-4 mb-4"
              >
                <label className="font-medium w-1/4">{label}:</label>
                <div className="w-3/4 font-medium">{renderField(field)}</div>
              </div>
            ))}
          </div>

          {/* Place of Work */}
          <div className="mt-8 space-y-4 text-blue-600">
            {[
              ["Working location", "location"],
              ["Address", "address"],
              ["Public holidays for", "publicHolidays"],
            ].map(([label, field]) => (
              <div key={field} className="flex items-center space-x-4 mb-4">
                <label className="font-medium w-1/4">{label}:</label>
                <div className="w-3/4 font-medium">{renderField(field)}</div>
              </div>
            ))}
          </div>
        </div>
        <Job details={details} />
      </div>
    </div>
  );
};

export default Contract;
