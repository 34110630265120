import React from 'react'
import Navbar  from '../Navbar'
import Sidebar from '../Sidebar'
import OrgDashboard from './OrgDashboard'
const Organisation = () => {
  return (
	<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
<OrgDashboard/>
	</div>
  </section>
  )
}

export default Organisation