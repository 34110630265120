import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import Overtime from './Overtime'
const OvertimeTimeline = () => {
  return (
	<section className="flex">
	<Sidebar />
	<div className="flex-1">
	  <Navbar />
	  <Overtime/>

	</div>
  </section>
	
  )
}

export default OvertimeTimeline