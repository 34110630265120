import React, { useState } from 'react';
import { FaPen, FaSave } from 'react-icons/fa';

const PersonalDashboardPart = ({user}) => {
  const [isEditing, setIsEditing] = useState(false);

  const [contactInfo, setContactInfo] = useState({
    personalEmail: user?.email,
    homePhone: user?.phone,
    workEmail: user?.email,
    personalPhone: user?.phone2,
  });

  const [personalInfo, setPersonalInfo] = useState({
    firstName: user?.firstName,
    middleName: user?.middleName,
    lastName: user?.lastName,
    dateOfBirth: user?.dob,
    nationality: 'British',
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e, section, field) => {
    const value = e.target.value;
    if (section === 'contact') {
      setContactInfo(prev => ({ ...prev, [field]: value }));
    } else if (section === 'personal') {
      setPersonalInfo(prev => ({ ...prev, [field]: value }));
    }
  };

  return (
    <div className="container mx-auto font-poppins px-4 py-6 bg-blue-400">
      {/* Contact Information Section */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-lg font-semibold">Contact Information</h2>
        {isEditing ? (
          <FaSave className="text-green-600 cursor-pointer" onClick={handleEditClick} />
        ) : (
          <FaPen className="text-green-600 cursor-pointer" onClick={handleEditClick} />
        )}
      </div>

      <div className="grid grid-cols-2 gap-8">
        {Object.entries(contactInfo).map(([key, value]) => (
          <div key={key}>
            <p className="text-gray-600 font-semibold">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</p>
            {isEditing ? (
              <input
                type="text"
                value={value}
                onChange={(e) => handleInputChange(e, 'contact', key)}
                className="text-black border border-gray-300 p-1"
              />
            ) : (
              <p className="text-black">{value}</p>
            )}
          </div>
        ))}
      </div>

      {/* Personal Information Section */}
      <div className="flex justify-between items-center mt-8 mb-8">
        <h2 className="text-lg font-semibold">Personal Information</h2>
        {isEditing ? (
          <FaSave className="text-green-600 cursor-pointer" onClick={handleEditClick} />
        ) : (
          <FaPen className="text-green-600 cursor-pointer" onClick={handleEditClick} />
        )}
      </div>

      <div className="grid grid-cols-3 gap-8">
        {Object.entries(personalInfo).map(([key, value]) => (
          <div key={key}>
            <p className="text-gray-600 font-semibold">{key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:</p>
            {isEditing ? (
              <input
                type="text"
                value={value}
                onChange={(e) => handleInputChange(e, 'personal', key)}
                className="text-black border border-gray-300 p-1"
              />
            ) : (
              <p className="text-black">{value}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalDashboardPart;
