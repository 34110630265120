import React, { useState } from "react";
import { FaCheck, FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";

const Bank = () => {
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [salary, setSalary] = useState({
    accountNumber: "30065060",
    sortCode: "20-59-61",
  });
  const [editedDetails, setEditedDetails] = useState(salary);

  // Toggle between expanded/collapsed view
  const toggle = () => {
    setExpanded(!expanded);
  };

  // Toggle between editing and viewing mode
  const toggleEdit = () => {
    if (isEditing) {
      // Save changes
      setSalary(editedDetails);
    }
    setIsEditing(!isEditing);
  };

  // Handle changes in input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails({ ...editedDetails, [name]: value });
  };

  return (
    <div className="container bg-blue-400 mt-7 p-6 rounded-lg shadow-lg ml-5 font-poppins">
      {/* Header section with toggle functionality */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <h1 className="text-xl font-bold">Bank Information</h1>
        {expanded ? <FaChevronUp /> : <FaChevronDown />}
      </div>

      {/* Description */}
      <p className="text-blue-600">Account Number and Sort Code</p>

      {/* Conditional rendering for expanded content */}
      {expanded && (
        <div className="mt-4">
          {isEditing ? (
            <>
              {/* Editable form fields */}
              <div className="flex flex-col space-y-4">
                <div>
                  <label className="block text-blue-600">Account Number</label>
                  <input
                    type="text"
                    name="accountNumber"
                    value={editedDetails.accountNumber}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                  />
                </div>

                <div>
                  <label className="block text-blue-600">Sort Code</label>
                  <input
                    type="text"
                    name="sortCode"
                    value={editedDetails.sortCode}
                    onChange={handleInputChange}
                    className="border-b-2 border-gray-400 focus:outline-none focus:border-blue-500"
                  />
                </div>

                {/* Save button */}
                <button
                  onClick={toggleEdit}
                  className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg"
                >
                  <FaCheck className="mr-2" />
                  Save
                </button>
              </div>
            </>
          ) : (
            <>
              {/* Display the current details */}
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="font-semibold">Account Number:</span>
                  <span className="bg-gray-200 py-1 px-3 rounded-md">
                    {salary.accountNumber}
                  </span>
                </div>

                <div className="flex justify-between">
                  <span className="font-semibold">Sort Code:</span>
                  <span className="bg-gray-200 py-1 px-3 rounded-md">
                    {salary.sortCode}
                  </span>
                </div>

                {/* Edit button */}
                <button
                  onClick={toggleEdit}
                  className="mt-4 bg-green-200 px-4 py-2 rounded-full flex items-center"
                >
                  <FaEdit className="mr-2" />
                  Edit
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Bank;
