export const links = [
	{ name: 'Home', url: '/', icon: '/img/home.svg' },
	{ name: 'Employees', url: '/org', icon: '/img/employe.svg' },
	{ name: 'Calender', url: '/calender', icon: '/img/calender.svg' },
	{ name: 'Clock-ins', url: '/clock-ins', icon: '/img/clock.svg' },
	{ name: 'Documents', url: '/documents', icon: '/img/document.svg' },
	{ name: 'E-Learning', url: '/e-learning', icon: '/img/learning.svg' },
	
	{ name: 'Payroll', url: '/payroll', icon: '/img/pay.svg' },
	{ name: 'Expenses', url: '/expenses', icon: '/img/expenses.svg' },
	{ name: 'Data protection', url: '/data', icon: '/img/data.svg' },
]
export const link = (userId) => [
	{ name: 'Absense', url: `/profile/${userId}` },
	{ name: 'Employment', url: `/contract/${userId}` },
	{ name: 'Overtime', url: `/overtime/${userId}` }, // Adjust as needed
	{ name: 'Personal', url: `/personal/${userId}` }, // Adjust as needed
	{ name: 'Emergencies', url: `/emergency/${userId}` }, // Adjust as needed
	{ name: 'Timesheet', url: `/timesheet/${userId}` }, // Adjust as needed
	{ name: 'Documents', url: `/documents/${userId} ` }, // Adjust as needed
  ];
  