import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import EmergencyDashboard from './EmergencyDashboard'
const Emergencies = () => {
  return (
	<>
	  <section className="flex">
      <Sidebar />
      <div className="flex-1">
        <Navbar />
        <EmergencyDashboard />
      </div>
    </section></>
  )
}

export default Emergencies